.scrolling-area__area {
    overflow-y: hidden;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    padding-bottom: calc(4rem / 16);
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
}

/*.scrolling-area__area::-webkit-scrollbar-track {*/
/*    -webkit-box-shadow: inset 0 0 calc(8rem / 16) rgba(0, 0, 0, 0.2);*/
/*    background-color: transparent;*/
/*    transition: all 120ms ease;*/
/*}*/

/*.scrolling-area__area::-webkit-scrollbar {*/
/*    width: calc(4rem / 16);*/
/*    height: calc(4rem / 16);*/
/*    background: var(--color-light-grey);*/
/*    transition: all 120ms ease;*/
/*}*/

/*.scrolling-area__area::-webkit-scrollbar-thumb {*/
/*    background-color: var(--color-primary);*/
/*    transition: all 120ms ease;*/
/*}*/


.scrolling-area__area--slider {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
.scrolling-area__area--slider::-webkit-scrollbar {
    display: none;
}
.scrolling-area__area--slider {
    display: flex;
    flex-wrap: nowrap;
    padding-bottom: 0;
    position: relative;
}
.scrolling-area__item {
    width: 100%;
    flex-shrink: 0;
    scroll-snap-align: start;
}

.scrolling-area--shadow:before,
.scrolling-area--shadow:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: calc(48rem/16);
    background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 100%);
    z-index: 1;
    @media (min-width: 768px) {
        width: calc(160rem/16);
    }
}
.scrolling-area--shadow:after {
    right: auto;
    left: 0;
    background: linear-gradient(270deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 100%);
}
.scrolling-area--shadow .scrolling-area__arrow {
    color: var(--color-primary);
}

.scrolling-area__arrow.scrolling-area__arrow {
    position: absolute;
    top: 50%;
    right: calc(8rem/16);
    color: white;
    text-shadow: 0 0 12px black;
    font-size: calc(16rem / 16);
    z-index: 2;
    padding: calc(8rem/16);
    transform: translateY(-50%);
    line-height: 1;

    @media screen and (min-width: 768px) {
        font-size: calc(32rem / 16);
    }
}

.scrolling-area__arrow.scrolling-area__arrow--prev {
    right: auto;
    left: calc(8rem/16);
}

/* big arrows */
.scrolling-area--big-arrows .scrolling-area__arrow.scrolling-area__arrow {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    color: var(--color-primary);
    padding: 0 calc(20rem/16);
    transform: none;
}
.scrolling-area--big-arrows .scrolling-area__arrow.scrolling-area__arrow--prev {
    right: auto;
    left: 0;
}

.scrolling-area__arrow[disabled] {
    opacity: .3;
}
/*.scrolling-area__nav {*/
/*    margin-top: calc(10rem / 16);*/
/*}*/
/*.scrolling-area__nav__dot + .scrolling-area__nav__dot {*/
/*    margin-left: calc(4rem / 16);*/
/*}*/
/*.scrolling-area__nav__dot {*/
/*    width: calc(8rem / 16);*/
/*    height: calc(8rem / 16);*/
/*    background: var(--color-sand);*/
/*    border: 0;*/
/*    box-shadow: none;*/
/*    border-radius: 50%;*/
/*    flex-shrink: 0;*/
/*    padding: 0;*/
/*}*/
/*.scrolling-area__nav__dot.is-active {*/
/*    background: var(--color-primary);*/
/*}*/
