.hero.hero {
    position: relative;
    padding-top: 6%;
    @media (max-width: 767px) {
        padding-top: 12%;
    }
    @media (min-width: 768px) {
        margin-top: -52px; /* anchornav-height (to make a background for sharing) */
    }
}

.hero__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100vh - 144px + 30px); /* nav-height + nav roundness (top-margin)*/
    z-index: -1;
    margin-top: -30px;
    @media (max-width: 767px) {
        height: calc(60vh - 32px);
    }
}

.hero__bg__img-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
}

.hero__bg__img-wrapper:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: -1px;
    width: calc(256rem/16);

    background: linear-gradient(270deg, rgba(216,216,216,0) 0%, #F5F8F8 100%);
}

.hero__bg__img {
    height: 100%;
    width: auto;
}

.hero__heading {
    margin-bottom: 5%;
    max-width: 70%;
    @media (max-width: 767px) {
        margin-bottom: 24%;
    }
}

.hero-teaser__content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;

    color: white;
    text-shadow: 0 0 20px rgba(0,0,0,.2);

    z-index: 1;
}

.hero-teaser__title {
    font-family: var(--font-default-bold);
    line-height: calc(50/35);

    font-size: responsive 1.5rem 2rem; /* min-size, max-size */
    font-range: 992px 1440px; /* viewport widths between which font-size is fluid */

    @media (max-width: 767px) {
        font-size: calc(16rem/16);
    }
    @media (min-width: 768px) {
        letter-spacing: 1px;
    }
}

.hero-teaser__subtitle {
    letter-spacing: 1.2px;
    line-height: calc(26/18);
    @media (min-width: 768px) {
        letter-spacing: 2px;
        font-size: calc(18rem/16);
    }
}

.hero-teaser__img:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    opacity: 0.4;
    background: linear-gradient(215deg, #EDE1D3 0%, #465A6C 100%);
}

.hero-teaser {
    position: relative;
}

@media (max-width: 768px) {
    .hero-teaser--offset-xs {
        top: calc(90rem/16);
    }

}

@media (min-width: 768px) {
    .hero-teaser--offset-md {
        margin-top: calc(60rem/16);
    }
}