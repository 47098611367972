.effect-module-thead-th{
    width: 27vw;
    @media screen and (max-width: 767px){
       width: calc(100rem/16);
       word-break: break-word;
    }
}

.table__effect-timeline {
    width: calc((336rem)/16 + 1.25rem);
    @media screen and (min-width: 768px) {
        width: calc((468rem)/16 + 2rem);
    }
}