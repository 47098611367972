.floating-text-box{
    float: left;
    margin-right: calc(15rem/16);
    margin-bottom: calc(10rem/16);
    
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-size: calc(40rem/16);
    font-family: var(--font-default-bold);
    color: #fff;
    background-color: var(--color-primary);
    padding: calc(13rem/16) calc(35rem/16);
    margin-top: calc(7rem/16);
}