:root {
    --color-primary: #C0D1CF;
    --color-secondary: #222B40;
    --color-text-default: #222B40;
    --color-text-muted: #767D89;

    --color-default: #222B40;
    --color-white: #FFF;
    --color-light: #F5F8F8;
    --color-grey: #dcdfe1;
    --color-light-grey: #F5F8F8;
    --color-dark-grey: #767D89;
    --color-dark: #1D1E25;

    --color-success: #28A745;
    --color-info: #FFC107;
    --color-warning: #ffc107;
    --color-info-blue: #17A2B8;
    --color-danger: #DC3545;

    --color-primary-contrast: #222B40;
    --color-secondary-contrast: #FFF;
    --color-success-contrast: #FFF;
    --color-info-contrast: var(--color-text-default);
    --color-warning-contrast: var(--color-text-default);
    --color-danger-contrast: #FFF;
    --color-light-contrast: var(--color-text-default);
    --color-dark-contrast: #FFF;
    --color-white-contrast: var(--color-text-default);
    --color-default-contrast: #FFF;

    --color-primary-dark: #b4c5c3;
    --color-primary-darker: #7a9995;
    --color-secondary-dark: #192134;
    --color-success-dark: #12862B;
    --color-info-dark: #967102;
    --color-warning-dark: #967102;
    --color-danger-dark: #cc2333;
    --color-light-dark: #e5eceb;
    --color-dark-dark: #1D1E25;
    --color-white-dark: #F8F9FA;
    --color-default-dark: #1f232a;

    --color-primary-light: #ccdddb;
    --color-secondary-light: #3c4563;
    --color-success-light: #1EE048;
    --color-info-light: #FBBD03;
    --color-warning-light: #FBBD03;
    --color-danger-light: #e15260;
    --color-light-light: #FAFBFB;
    --color-dark-light: #3f404b;
    --color-white-light: #FFF;
    --color-default-light: #3b4552;

    --font-default: "Jost-Regular", sans-serif;
    --font-default-bold: "Jost-Bold", sans-serif;

    --font-size-default: calc(18rem/16);
}
