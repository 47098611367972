.timeline-vertical {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}

.timeline-vertical:before {
    content: '';
    position: absolute;
    width: calc(1rem/16);
    top: 0;
    bottom: 0;
    background: var(--color-primary);
    left: calc(22rem/16);
    z-index: 0;

    @media screen and (min-width: 768px){
        left: calc(30rem/16);
    }
}

.timeline-vertical>li+li{
    margin-top: calc(30rem/16);

    @media screen and (min-width: 768px){
        margin-top: calc(40rem/16);
    }
}