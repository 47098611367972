.icon-in-text {
    vertical-align: -.12em;
}
.icon-in-text--lg {
    font-size: 1.2em;
    vertical-align: -.18em;
}
.icon-in-text--sm {
    font-size: .8em;
}

/* Icons specific placement */

/*.icon-in-text.icon-arrow-right {
    vertical-align: -.2em;
}*/