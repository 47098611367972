:root {
    --nav-tab-link-margin: calc(-12rem/16);

    @media screen and (min-width: 768px){
        --nav-tab-link-margin: calc(-16rem/16);
    }
}

.nav-tabs.nav-tabs--primary{
    border-bottom: 0;
    position: relative;
    z-index: 2;
    padding: 0;
}

.nav-tabs.nav-tabs--primary .nav-link{
    border-radius: calc(1000rem/16);
    font-family: var(--font-default-bold);
    font-size: calc(16rem/16);
    border: 2px solid var(--color-primary);
    
    @media screen and (max-width: 767px){
       font-size: calc(11rem/16);
        padding: calc(5rem/16) calc(25rem/16);
    }
}

.nav-tabs.nav-tabs--primary .nav-link:focus,
.nav-tabs.nav-tabs--primary .nav-link:hover {
    border-color: var(--color-primary);
}

.nav-tabs--primary .nav-link{
    background-color: #fff;
    color: var(--color-primary);
    letter-spacing: 0.4px;
}

.nav-tabs--primary .nav-link.active{
    background-color: var(--color-primary);
    color: var(--color-text-default);
    z-index: 1;
    position: relative;
}

.nav-tabs--primary .nav-item:first-child{
    margin-right: var(--nav-tab-link-margin);
}

.nav-tabs--primary .nav-item:last-child{
    margin-left: var(--nav-tab-link-margin);
}

.nav-tabs--primary .nav-item:not(:first-child):not(:last-child){
    margin: 0 var(--nav-tab-link-margin);
}

.nav-tabs--primary .nav-item {
    margin-bottom: 0;
}

/* Tab Content */

.tab-content .tab-pane {
    display: none;
}

.tab-content .active {
    display: block;
}

.tab-content--primary .tab-pane{
    background-color: var(--color-light-light);
    padding: calc(55rem/16) 0;
    border: 2px solid var(--color-primary);
    @media (max-width: 767px) {
        margin-left: calc(var(--row-gutter) * -1);
        margin-right: calc(var(--row-gutter) * -1);
        padding: calc(44rem/16) 1px calc(20rem/16);
    }
}

.tab-content--primary{
    margin-top: calc(-21rem/16);
}

@media screen and (max-width: 767px) {
    .tab-pane--no-border-right-xs.tab-pane--no-border-right-xs {
        border-right: none;
    }
}