.footer{
    background-color: var(--color-dark-grey);
    color: #fff;
    padding: calc(26rem/16) 0;
    
    @media screen and (max-width: 767px){
        padding-top: calc(15rem/16);
       padding-bottom: calc(85rem/16);
        font-size: calc(12rem/16);
    }
}