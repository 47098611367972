.custom-scrollbar{
    padding-bottom: calc(20rem/16);

    /* The emerging W3C standard
   that is currently Firefox-only */
    scrollbar-width: thin;
    scrollbar-color: #d7d7d7 #E3E3E3;
}


/* Works on Chrome/Edge/Safari */

.custom-scrollbar::-webkit-scrollbar {
    height: calc(6rem/16);
    border-radius: calc(1000rem/16);
}

/* Track */
.custom-scrollbar::-webkit-scrollbar-track {
    background: #e9e9e9;
    border-radius: calc(3rem/16);
}

/* Handle */
.custom-scrollbar::-webkit-scrollbar-thumb {
    background: #d7d7d7;
    border-radius: calc(3rem/16);
    transition: all 250ms ease;
}

/* Handle on hover */
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #cfcfcf;
}


.custom-scrollbar.active,
.custom-scrollbar.active div{
    cursor: grabbing;
}