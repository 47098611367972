.header-module-area{
    @media screen and (max-width: 767px){
       padding-top: calc(20rem/16);
    }
}

@media (min-width: 768px) {
    .header-module__grid {
        display: grid;
        grid-template-columns: 40% 60%;
        grid-template-rows: repeat(10, auto);
    }
}

.header-module__title{
    @media screen and (max-width: 767px){
       font-size: calc(25rem/16);
    }
}
.header-module__image{
    position: relative;
    margin-right: calc(48rem/16);
    @media (max-width: 767px) {
        margin: 1rem 0 calc(48rem/16);
    }
    @media (min-width: 768px) {
        grid-column: 1 / span 1;
        grid-row: 1 / -1;
        align-self: start;
    }
}

.header-module__image img{
    position: relative;
    z-index: 1;
}

.header-module__image:after{
    content: '';
    height: calc(160rem/16);
    width: calc(800rem/16);
    position: absolute;
    right: calc(32rem/16);
    bottom: calc(-32rem/16);
    background-color: var(--color-primary);
    z-index: 0;
    @media (min-width: 768px) {
        height: calc(405rem/16);
        bottom: calc(-55rem/16);
        right: calc(55rem/16);
    }
}

.header-module__info-items{
    font-size: calc(10rem/16);
    margin-top: calc(15rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(16rem/16);
    }
}

.header-module__info-item{
    text-transform: uppercase;

    @media screen and (min-width: 768px){
        letter-spacing: 0.4px;
    }
}

.header-module__info-items>li:not(:last-child){
    padding-right: calc(14rem/16);
    position: relative;
}

.header-module__info-items>li:not(:last-child):after{
    content: '';
    position: absolute;
    right: calc(1rem/16);
    top: calc(4rem/16);
    bottom: calc(4rem/16);
    width: calc(1rem/16);
    background-color: var(--color-default);
}

.header-module__fact{
    border: 2px solid #fff;
    padding: calc(7rem/16) calc(12rem/16);
    font-size: calc(14rem/16);
    display: flex;
    align-items: center;
    @media (min-width: 768px) {
        font-size: calc(20rem/16);
        padding: calc(7rem/16) calc(16rem/16);
    }
}

.header-module__experts{
    margin-top: calc(20rem/16);
}

.header-module__experts .list-inline-item:not(:last-child){
    margin-right: 0;
}