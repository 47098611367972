.video-overlay {
    z-index: 1;
}

.video-overlay__play-btn {
    width: calc(80rem/16);
    height: calc(80rem/16);
    background-color:#fff;
    border: none;
    border-radius: 50%;
    box-shadow: 0 10px 20px 0 rgba(34,43,64,0.1);
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
    padding-left: calc(8rem/16);

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: calc(32rem/16);
    color: var(--color-primary);

    @media (min-width: 768px) {
        width: calc(112rem/16);
        height: calc(112rem/16);
        font-size: calc(44rem/16);

    }
}