.vertical-gutter--1 {
    margin-top: -.25rem;
}
.vertical-gutter--1 > .vertical-gutter__item ,
.vertical-gutter--1 > [class^="col"],
.vertical-gutter--1 > [class*=" col"]{
    margin-top: .25rem;
}
.vertical-gutter--2 {
    margin-top: -.5rem;
}
.vertical-gutter--2 > .vertical-gutter__item,
.vertical-gutter--2 > [class^="col"],
.vertical-gutter--2 > [class*=" col"] {
    margin-top: .5rem;
}

@media screen and (max-width: 767px){
    .vertical-gutter--xs-2 {
        margin-top: -.5rem;
    }
    .vertical-gutter--xs-2 > .vertical-gutter__item,
    .vertical-gutter--xs-2 > [class^="col"],
    .vertical-gutter--xs-2 > [class*=" col"] {
        margin-top: .5rem;
    }
}
.vertical-gutter--3 {
    margin-top: -1rem;
}
.vertical-gutter--3 > .vertical-gutter__item,
.vertical-gutter--3 > [class^="col"],
.vertical-gutter--3 > [class*=" col"] {
    margin-top: 1rem;
}
.vertical-gutter--4 {
    margin-top: -1.5rem;
}
.vertical-gutter--4 > .vertical-gutter__item,
.vertical-gutter--4 > [class^="col"],
.vertical-gutter--4 > [class*=" col"] {
    margin-top: 1.5rem;
}
.vertical-gutter--5 {
    margin-top: -3rem;
}
.vertical-gutter--5 > .vertical-gutter__item,
.vertical-gutter--5 > [class^="col"],
.vertical-gutter--5 > [class*=" col"] {
    margin-top: 3rem;
}
.vertical-gutter--6 {
    margin-top: -4.5rem;
}
.vertical-gutter--6 > .vertical-gutter__item,
.vertical-gutter--6 > [class^="col"],
.vertical-gutter--6 > [class*=" col"] {
    margin-top: 4.5rem;
}


.vertical-gutter--60 {
    @media screen and (min-width: 768px){
        margin-top: calc(-60rem/16);
    }
    margin-top: calc(-30rem/16);
}
.vertical-gutter--60 > .vertical-gutter__item,
.vertical-gutter--60 > [class^="col"],
.vertical-gutter--60 > [class*=" col"] {
    margin-top: calc(30rem/16);
    @media screen and (min-width: 768px){
       margin-top: calc(60rem/16);
    }
}


@media screen and (min-width: 768px) {

    .vertical-gutter--md-6 {
        margin-top: -4.5rem;
    }
    .vertical-gutter--md-6 > .vertical-gutter__item,
    .vertical-gutter--md-6 > [class^="col"],
    .vertical-gutter--md-6 > [class*=" col"] {
        margin-top: 4.5rem;
    }

    .vertical-gutter--md-90 {
        margin-top: calc(-90rem/16);
    }
    .vertical-gutter--md-90 > .vertical-gutter__item,
    .vertical-gutter--md-90 > [class^="col"],
    .vertical-gutter--md-90 > [class*=" col"] {
        margin-top: calc(90rem/16);
    }

}