.btn-shadow{
    background-color: #fff;
    box-shadow: 0 10px 20px 0 rgba(34,43,64,0.1);
}

.btn-cta {
    background: linear-gradient(216.86deg, #F98B38 0%, #F0531A 100%);
    color: white;
    font-family: var(--font-default-bold);
    box-shadow: 0 10px 20px 0 rgba(34,43,64,0.1);
    /*background-size: 200% 100%;*/
    /*transition: all .4s;*/

}

.btn-cta:hover {
    color: white;
    /*background-position: 100% 0;*/
}

.btn-link--dark {
    font-family: var(--font-default-bold);
    color: var(--color-default);
}

.btn-link--dark:hover {
    color: var(--color-default-light);
}