.ingredients__inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: calc(4rem/16) calc(12rem/16);

    @media (min-width: 768px) {
        padding: calc(24rem/16);
    }
}

.ingredients__logo {
    @media (max-width: 768px) {
        height: calc(20rem/16);
    }
}

.ingredients__items {
    @media (min-width: 768px) {
        padding: 0 11%;
    }
}

.ingredients__caption {
    font-size: calc(8rem/16);
    @media (min-width: 768px) {
        font-size: calc(16rem/16);
        letter-spacing: 2px;
    }
}

.ingredient + .ingredient {
    margin-top: calc(8rem/16);
    @media (min-width: 768px) {
        margin-top: calc(44rem/16);
    }
}

.ingredient__text {
    @media (max-width: 767px) {
        margin-top: calc(4rem/16);
        font-size: calc(10rem/16);
    }
}

.ingredient__icon {
    width: calc(60rem/16);
    height: calc(60rem/16);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border: 1px solid white;
    font-size: calc(25rem/16);
    margin: 0 auto;
    @media (max-width: 767px) {
        width: calc(16rem/16);
        height: calc(16rem/16);
        font-size: calc(8rem/16);
    }
}