.embed-responsive-cover{
    height: 100%;
}

.embed-responsive-cover .embed-responsive-item{
    width: auto;
    min-height: 100%;
    left: 50%;
    transform: translateX(-50%);
}

@media screen and (min-width: 768px){
    .embed-responsive-md-cover{
        height: 100%;
    }

    .embed-responsive-md-cover .embed-responsive-item{
        width: auto;
        min-height: 100%;
        left: 50%;
        transform: translateX(-50%);
    }

}


@media (max-width: 767px) {
    .embed-responsive-2by1-xs:before {
        padding-top: calc(1% / 2 * 100);
    }

}