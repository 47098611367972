.comment-wrapper {
    padding: calc(8rem / 16) calc(16rem / 16) calc(16rem / 16) calc(8rem / 16);
    border-radius: calc(24rem/16);
    box-shadow: 0 calc(6rem/16) calc(10rem/16) 0 rgba(34,43,64,0.1);
    background: var(--color-light-grey);
    @media (min-width: 768px) {
        padding: calc(40rem/16);
        padding-bottom: calc(32rem/16);
    }
}

.bg-slide-light .comment-wrapper {
    background: white;
}

.comment__name,
.comment__text {
    font-size: calc(18rem/16);
}

.comment__body {
    position: relative;
}

.comment--has-replies > div > .comment__body:before {
    content: '';
    position: absolute;
    left: calc(-32rem/16);
    bottom: calc(-56rem/16);
    top: calc(50rem/16);
    width: 2px;
    background: var(--color-primary);
}

.comment__img {
    position: relative;
    width: 44px;
    height: auto;
}

.comment--sub-comment {
    margin-top: calc(32rem/16);
}

.comment--sub-comment .comment__img:before {
    content: '';
    position: absolute;
    left: calc(-32rem/16);
    width: calc(24rem/16);
    top: calc(22rem/16);
    height: 2px;
    background: var(--color-primary);
}