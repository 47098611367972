.expert-module-area.slide{
    padding-bottom: 0;
}

.expert-module{
    position: relative;
    z-index: 1;
}

.expert-module__toptitle{
    font-family: var(--font-default-bold);
    font-size: calc(16rem/16);
    line-height: calc(20/16);
    text-transform: uppercase;
    letter-spacing: 0.8px;
    margin-bottom: calc(5rem/16);
}

.expert-module__text{
    font-size: calc(18rem/16);
    line-height: calc(28/19);
    margin-top: calc(12rem/16);
    margin-bottom: calc(30rem/16);
}

.expert-module__img{
    @media screen and (min-width: 768px){
       max-width: calc(465rem/16);
    }

    @media screen and (max-width: 767px){
        max-width: 65%;
        margin: 0 auto;
    }

}