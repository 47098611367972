.icon-text-item__icon{
    width: calc(60rem/16);
    height: calc(60rem/16);
    border-radius: 50%;
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    justify-content: center;
    background-color: var(--color-primary);
    margin-right: calc(18rem/16);
    font-size: calc(23rem/16);

    @media screen and (max-width: 767px){
        margin: 0 auto calc(15rem/16);
    }
}

.icon-text-item__title{
    @media screen and (max-width: 767px){
       font-size: calc(18rem/16);
    }
}

/* icon-text-item timeline */

.icon-text-item--timeline .icon-text-item__title{
    margin-bottom: 0;
    font-size: calc(16rem/16);
    @media screen and (min-width: 768px){
       font-size: calc(20rem/16);
    }
}

.icon-text-item--timeline .icon-text-item__text{
    font-size: calc(16rem/16);
    color: var(--color-dark-grey);
}

.icon-text-item--timeline .icon-text-item__icon{
    margin-right: calc(10rem/16);
    margin-left: 0;
    margin-bottom: 0;
    background-color: #fff;
    border: 1px solid var(--color-primary);
    z-index: 1;

    @media screen and (max-width: 767px){
        width: calc(45rem/16);
        height: calc(45rem/16);
        font-size: calc(20rem/16);
    }
}

.icon-text-item--timeline.active .icon-text-item__icon{
    background-color: var(--color-primary);
}