.hover-zoom {
    backface-visibility: hidden;
}
.hover-zoom__img {
    transition: transform 0.4s ease;
}
@media screen and (min-width: 768px) {
    .hover-zoom:hover .hover-zoom__img {
        transform: scale(1.07);
    }
}
