.comparison-module-product:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: calc(120rem/16);
    background: var(--color-primary);
}

.comparison-module-product__badge {
    position: absolute;
    top: calc(8rem/16);
    right: calc(8rem/16);
    width: calc(64rem/16);
    z-index: 1;
    @media (max-width: 767px) {
        width: calc(48rem/16);
    }
}

.comparison-module-product__title {
    font-size: calc(18rem/16);
    @media (min-width: 768px) {
        font-size: calc(22rem/16);
    }
}

.comparison-module-product__subtitle {
    font-family: var(--font-default);
    font-size: calc(12rem/16);
    margin-top: calc(8rem/16);
    @media (min-width: 768px) {
        font-size: calc(16rem/16);
    }
}

.comparison-module-product__number {
    position: absolute;
    top: calc(124rem/16);
    left: calc(16rem/16);
    font-size: calc(40rem/16);
    line-height: 1;
}

.comparison-module-product__img-wrapper {
    position: relative;
    height: calc(140rem/16);

    display: flex;
    align-items: center;
    justify-content: center;

    margin-top: calc(12rem/16);
}

.comparison-module-product__img {
    max-height: 100%;
    width: auto;
}