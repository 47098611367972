.quote-slide {
    position: relative;
    overflow: hidden;
    padding-top: calc(80rem/16);
    padding-bottom: calc(100rem/16);
    background-size: cover;
    background-position: center;
    @media (min-width: 768px) {
        padding-top: calc(132rem/16);
        padding-bottom: calc(148rem/16);
    }
}

.quote-slide:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.7;
    background: linear-gradient(215deg, #EDE1D3 0%, #465A6C 100%);
}

.quote-slide__text {
    position: relative;
    font-size: calc(32rem/16);
    font-family: var(--font-default-bold);
    line-height: calc(55/60);
    color: white;
    font-style: italic;
    z-index: 1;
    text-shadow: 0 0 20px rgba(0,0,0,.3);

    @media (min-width: 768px) {
        font-size: calc(60rem/16);
        max-width: calc(640rem/16);
        margin-left: 8.333%
    }
}

.quote-slide--sm .quote-slide__text {
    font-size: calc(24rem/16);
    @media (min-width: 768px) {
        font-size: calc(48rem/16);
    }
}

.quote-slide--lg .quote-slide__text {
    font-size: calc(36rem/16);
    @media (min-width: 768px) {
        font-size: calc(72rem/16);
    }
}

.quote-slide__highlight {
    color: var(--color-primary);
}

.quote-slide__text:after {
    content: '"';
    font-size: 240px;
    opacity: .4;
    position: absolute;
    right: 0;
    top: 50%;
    color: var(--color-primary);
    z-index: -1;
    @media (min-width: 768px) {
        font-size: 360px;
        right: -20px;
    }
}