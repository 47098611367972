.subnav{
    position: absolute;
    left: 0;
    right: 0;
    background-color: var(--color-light);
    padding: calc(30rem/16) 0;
    z-index: 1;
    overflow: hidden;
    font-size: calc(16rem/16);

    @media screen and (min-width: 768px){
        opacity: 0;
        visibility: hidden;
        transition: opacity 200ms ease, visibility 200ms ease;
        transition-delay: 120ms;
        box-shadow: 0 6px 10px 0 rgba(34,43,64,0.1);
    }
    
    @media screen and (max-width: 767px){
        position: fixed;
        transform: translateX(-100%);
        height: calc(100vh - 62rem/16);
        top: calc(62rem/16);
        z-index: 1;
        transition: transform 250ms ease;
    }

}

.nav-item__main.is-open .subnav{
    @media screen and (min-width: 768px){
        opacity: 1;
        visibility: visible;
        transition: opacity .5s ease, visibility .5s ease;
    }

    @media screen and (max-width: 767px){
        transform: translateX(0);
    }
}

.subnav .nav-link{
    font-family: var(--font-default-bold);
    font-size: calc(18rem/16);
    
    @media screen and (max-width: 767px){
       text-align: center;
    }
}

.subnav .nav-item+.nav-item{
    @media screen and (max-width: 767px){
        margin-top: calc(10rem/16);
    }
}