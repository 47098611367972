.scroll-indicator.is-visible {
    opacity: 1;
}
.scroll-indicator {
    position: absolute;
    top: 0;
    right: -1px;
    bottom: 0;
    pointer-events: none;
    z-index: 20;

    width: calc(64rem/16);
    background: linear-gradient(270deg, rgba(251,252,252,1) 0%, rgba(250,251,251,0) 100%);

    opacity: 0;
    transition: opacity .3s;
}

.scroll-indicator--dark {
    background: linear-gradient(270deg, rgba(229, 236, 235, 1) 0%, rgba(250,251,251,0) 100%);
}
.scroll-indicator__arrow {
    position: absolute;
    right: calc(8rem/16);
    top: calc(-30rem/16);

    display: flex;
    justify-content: center;
    align-items: center;

    width: calc(16rem/16);
    height: calc(16rem/16);
    background: white;
    border-radius: 50%;
    box-shadow: 0 2px 5px 0 rgba(34,43,64,0.3);
    font-size: calc(6rem/16);
    @media (max-width: 767px) {
        top: calc(-20rem/16);
    }
}