.mt-30{
    margin-top: calc(30rem/16);
}

.mb-60 {
    margin-bottom: calc(60rem/16);
}

.mb-6 {
    margin-bottom: 4.5rem;
}

@media (min-width: 768px) {
    .mb-md-6 {
        margin-bottom: 4.5rem;
    }
}

/* Text Helper */
.text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


/* Text Color Helper */
.text-white { color: #ffffff; }
.text-color-default { color: var(--color-text-default); }


/* Background Color Helper */
.bg-light,
.bg-slide-light{
     background-color: var(--color-light);
}

.bg-slide-dark{
    background-color: var(--color-light-dark);
}

.bg-white-outline{
    background-color: #fff;
    border: 1px solid #e2e2e2;
}

.bg-dark-grey{
    background-color: var(--color-dark-grey);
    color: #fff;
}

.bg-gradient-primary{
    background: linear-gradient(226.09deg, #C0D1CF 0%, #465A6C 100%);
}

.bg-gradient-secondary{
    background: linear-gradient(214.82deg, #EDE1D3 0%, #465A6C 100%);
}

.bg-transparent{
    background: transparent;
}

/* Additional */
.btn-no-styling {
    border: none;
    background: none;
    text-align: left;
    padding: 0;
    color: inherit;
    font-family: inherit;
    font-weight: inherit;
    cursor: pointer;
}

.increased-click-area {
    padding: calc(8rem/16);
    margin: calc(-8rem/16);
}

.row--overflow-scroll,
.overflow-scroll{
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    flex-wrap: nowrap;
}

@media screen and (max-width: 767px){
    .row--overflow-scroll-xs,
    .overflow-scroll-xs{
        overflow-x: auto;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
        flex-wrap: nowrap;
    }

    .overflow-scroll-xs .nav-item{
        white-space: nowrap;
    }
}

.w-100-xs{
    @media screen and (max-width: 767px){
       width: 100%;
    }
}

hr {
    border-top: 1px solid var(--color-grey);
}

.hr-light{
    border-top: 2px solid var(--color-light);
}

.hr--content-block-divider {
    margin-top: calc(80rem/16);
    @media (max-width: 767px) {
        margin-top: calc(40rem/16);

    }
}

@media (max-width: 768px) {
    .no-container-padding-right-xs {
        margin-right: -1rem;
    }

}

.cursor-pointer {
    cursor: pointer;
}