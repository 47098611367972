.article-teaser__data {
    margin-bottom: calc(8rem/16);
    font-size: calc(14rem/16);
}

.article-teaser__body {
    padding: calc(16rem/16) 0 0;
}

@media (min-width: 768px) {
    .article-teaser__body {
        padding: calc(24rem/16) 0 calc(24rem/16) calc(40rem/16);
    }

    .article-teaser--big .article-teaser__data {
        margin-bottom: calc(16rem/16);
    }

    .article-teaser--big .article-teaser__body {
        padding: calc(32rem/16) 0 0;
    }
}


/* boxed version (white background on darker (page) body background) */
.article-teaser--boxed {
    background: white;
}

.article-teaser--boxed .article-teaser__body {
    padding: calc(24rem/16) calc(24rem/16) calc(32rem/16);
}

@media (min-width: 768px) {
    .article-teaser--boxed .article-teaser__body {
        padding: calc(32rem/16) calc(40rem/16) calc(24rem/16) calc(40rem/16);
    }

    .article-teaser--big.article-teaser--boxed .article-teaser__body {
        padding: calc(32rem/16) calc(40rem/16) calc(48rem/16);
    }
}

