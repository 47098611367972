.img-text-teaser__title {
    font-size: calc(24rem/16);
    line-height: calc(30/24);
    @media (max-width: 767px) {
        margin-top: calc(16rem/16);
    }
}

.img-text-teaser__text {
    font-size: calc(16rem/16);
    line-height: calc(24/16);
}

.img-text-teaser-slider {
    padding-left: 12%; /* fallback */
    padding-left: max(calc((100vw - 1040px) / 2 + 1rem), 1rem);
}

.img-text-teaser-slider__item {
    max-width: 80vw;
    margin-right: 7vw;
    scroll-snap-align: center;
    @media (min-width: 768px) {
        max-width: 60vw;
    }
}

.img-text-teaser-slider__item:first-child {
    scroll-snap-align: end;
}