.wysiwyg > p,
.wysiwyg > ol,
.wysiwyg > ul,
.wysiwyg table {
    margin-bottom: 1em;
}
.wysiwyg > p:last-child,
.wysiwyg > ol:last-child,
.wysiwyg > ul:last-child,
.wysiwyg table:last-child {
    margin-bottom: 0;
}
.wysiwyg img {
    max-width: 100%;
    height: auto !important; /*override inline style from wysiwyg*/
}
.wysiwyg a,
.custom-checkbox__text a {
    color: var(--color-primary-darker);
}
.wysiwyg a:hover,
.wysiwyg a:focus,
.wysiwyg a:active,
.custom-checkbox__text a:hover,
.custom-checkbox__text a:focus,
.custom-checkbox__text a:active {
    color: var(--color-primary-dark);
    text-decoration: underline;
}


.wysiwyg ul{
    list-style: none;
    padding-left: 0;
}

.wysiwyg ul:not(.list-unstyled) li{
    position: relative;
    padding-left: calc(32rem / 16);
}

.wysiwyg ul:not(.list-unstyled) li:after {
    position: absolute;
    top: calc(4rem/16);
    left: 0;
    width: calc(20rem/16);
    height: calc(20rem/16);

    content: '';
    border-radius: 100%;
    background: var(--color-primary);
}

.wysiwyg ul:not(.list-unstyled) li:before {
    position: absolute;
    top: calc(4rem/16);
    left: 0;
    width: calc(20rem/16);
    height: calc(20rem/16);

    position: absolute;
    font-family: "iconfont";
    content: var(--icon-circle);
    speak: none;
    font-style: normal;
    font-weight: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);

    font-size: calc(8rem / 16);

    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0;
    z-index: 1;
}

.wysiwyg ul:not(.list-unstyled) li + li {
    margin-top: calc(20rem/16);
}

/* ul with check icons*/
.ul--check ul:not(.list-unstyled) li:before{
    content: var(--icon-check);
    font-size: calc(7rem / 16);
}