.share-btn {
    display: flex;
    justify-content: center;
    align-items: center;

    color: white;
    font-size: calc(20rem/16);
    min-width: calc(52rem/16);
    min-height: calc(52rem/16);
    padding: .25rem;
    @media (min-width: 768px) {
        border-radius: 0;
    }
    @media (max-width: 767px) {
        box-shadow: 0 10px 20px 0 rgba(34,43,64,0.1);
    }
}

.share-btn:focus,
.share-btn:hover {
    background: white;
}

.share-btn--fb {
    background: #3b5998;
}

.share-btn--fb:hover,
.share-btn--fb:focus {
    color: #3b5998;
}

.share-btn--twitter {
    background: #1DA1F2;
}

.share-btn--twitter:hover,
.share-btn--twitter:focus {
    color: #1DA1F2;
}

.share-btn--whatsapp {
    background: #25D366;
}

.share-btn--whatsapp:hover,
.share-btn--whatsapp:focus {
    color: #25D366;
}

.share-btn--pinterest {
    background: #E60023;
}

.share-btn--pinterest:hover,
.share-btn--pinterest:focus {
    color: #E60023;
}