

/* rem gutters */
.row-gutter--0 {
    margin-left: 0;
    margin-right: 0;
}
.row-gutter--0 > [class^="col"]:not(th):not(td),
.row-gutter--0 > [class*=" col"]:not(th):not(td) {
    padding-left: 0;
    padding-right: 0;
}
.row-gutter--1 {
    margin-left: -.125rem;
    margin-right: -.125rem;
}
.row-gutter--1 > [class^="col"],
.row-gutter--1 > [class*=" col"]{
    padding-left: .125rem;
    padding-right: .125rem;
}
.row-gutter--2 {
    margin-left: -.25rem;
    margin-right: -.25rem;
}
.row-gutter--2 > [class^="col"],
.row-gutter--2 > [class*=" col"]{
    padding-left: .25rem;
    padding-right: .25rem;
}
.row-gutter--3 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
}
.row-gutter--3 > [class^="col"],
.row-gutter--3 > [class*=" col"]{
    padding-left: .5rem;
    padding-right: .5rem;
}
.row-gutter--4 {
    margin-left: -.75rem;
    margin-right: -.75rem;
}
.row-gutter--4 > [class^="col"],
.row-gutter--4 > [class*=" col"] {
    padding-left: .75rem;
    padding-right: .75rem;
}

/*use a media query for bigger row gutters; otherwise the page is scrollable on mobile devices */
@media screen and (min-width: 768px) {
    .row-gutter--5 {
        margin-left: -1.5rem;
        margin-right: -1.5rem;
    }
    .row-gutter--5 > [class^="col"],
    .row-gutter--5 > [class*=" col"] {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}


/***********************************************************
               ATTENTION: Deprecated
    Pixel Gutters should not be used any more.
    They are included for backward compatibility only.
    Please use rem gutters instead

 ***********************************************************/


/* pixel gutters */
.row.row--gutter-width-0 {
    margin-left: 0;
    margin-right: 0;
}
.row--gutter-width-0 > [class^="col"],
.row--gutter-width-0 > [class*=" col"] {
    padding-left: 0;
    padding-right: 0;
}
.row.row--gutter-width-10 {
    margin-left: -5px;
    margin-right: -5px;
}
.row--gutter-width-10 > [class^="col"],
.row--gutter-width-10 > [class*=" col"] {
    padding-left: 5px;
    padding-right: 5px;
}

.row.row--gutter-width-60 {
    margin-left: calc(-30rem/16);
    margin-right: calc(-30rem/16);
}
.row--gutter-width-60 > [class^="col"],
.row--gutter-width-60 > [class*=" col"] {
    padding-left: calc(30rem/16);
    padding-right: calc(30rem/16);
}

.row.row--gutter-width-100 {
    @media screen and (min-width: 1500px){
        margin-left: calc(-50rem/16);
        margin-right: calc(-50rem/16);
    }

    @media screen and (min-width: 768px){
        margin-left: calc(-30rem/16);
        margin-right: calc(-30rem/16);
    }

}
.row--gutter-width-100 > [class^="col"],
.row--gutter-width-100 > [class*=" col"] {
    @media screen and (min-width: 1500px){
        padding-left: calc(50rem/16);
        padding-right: calc(50rem/16);
    }
   @media screen and (min-width: 768px){
       padding-left: calc(30rem/16);
       padding-right: calc(30rem/16);
   }
}