table.table th,
table.table td{
    padding: calc(10rem/16);
    
    @media screen and (min-width: 768px){
        padding: calc(8rem/16) calc(16rem/16);
    }
}

.wysiwyg table{
    width: 100%;
}

.wysiwyg table tbody th{
    background-color: var(--color-grey);
    font-family: var(--font-default);
    border-left: 0;
}

.wysiwyg table td{
    background-color: #fff;
}

.wysiwyg table td,
.wysiwyg table th{
    border-bottom: none;
}

/* table light */

.wysiwyg .table-bordered{
    border: none;
}

.table-bordered.table-light{
    border-color: var(--color-light);
}

.table-light{
    background-color: transparent;
}

.table-light tbody+tbody,
.table-light td,
.table-light th,
.table-light thead th {
    border-color: var(--color-light);
    background-color: #fff;
}

.table th.th-corner{
    background-color: var(--color-grey);
    border-top: 0;
    border-left: 0;
}

.table tbody th.fixed,
.table tbody td.fixed,
.table th.th-corner.fixed {
    position: sticky;
    left: 0;
    z-index: 5;
}

.table-bordered,
.table-bordered td,
.table-bordered th{
    border-width: 0.5px;
}


.td--align-bottom {
    display: flex;
    align-items: flex-end;
}

@media (max-width: 767px) {
    .table--wide-cols-xs td,
    .table--wide-cols-xs th {
        min-width: 75vw;
    }
}

/*  table col widths */
.table-layout-fixed {
    table-layout: fixed;
}

.table__col-1 {
    width: 25vw;
    @media (min-width: 768px) {
        width: 8vw;
    }
}

.table__col-2 {
    width: 50vw;
    @media (min-width: 768px) {
        width: 16vw;
    }
}

.table__col-3 {
    width: 65vw;
    @media (min-width: 768px) {
        width: 24vw;
    }
}

@media (min-width: 768px) {
    .table__col-md-1 {
        width: 8vw;
    }

    .table__col-md-2 {
        width: 16vw;
    }

    .table__col-md-3 {
        width: 24vw;
    }
}
