.form-control.has-value{
    padding: calc(15rem/16) calc(20rem/16) calc(4rem/16) calc(20rem/16);
}

.input-group-md .form-group:first-child .form-control{
    @media screen and (min-width: 768px){
        border-top-left-radius: calc(1000rem/16);
        border-bottom-left-radius: calc(1000rem/16);
    }

}

.input-group-md .form-control{
    @media screen and (min-width: 768px){
        border-radius: 0;
    }
}

.input-group.input-group-md>.input-group-append>.btn{
    @media screen and (max-width: 767px){
        border-top-left-radius: calc(1000rem/16);
        border-bottom-left-radius: calc(1000rem/16);
    }
}

.form-group {
    position: relative;
}

.form-control {
    box-shadow: 0 2px 6px 0 rgba(34,43,64,0.1);
}

textarea.form-control {
    border-radius: 12px;
}

.text-area .form-control.has-value {
    padding-top: calc(24rem/16);
}

legend {
    font-size: calc(16rem/16);
    margin-bottom: 0;
}