.accordion{
    box-shadow: 0 7px 20px 0 rgba(34,43,64,0.1);
}

.accordion__card{
    border: none;
}

.accordion .accordion__card.accordion__card:not(:last-child){
    border-bottom: 1px solid var(--color-light);
}

.accordion__header{
    padding: 0;
    background-color: #fff;
    border-bottom: none;
}

.accordion__header-link{
    padding: calc(12rem/16) calc(20rem/16);
    font-family: var(--font-default-bold);
    font-size: calc(16rem/16);
    display: block;
    @media screen and (min-width: 768px){
        font-size: calc(18rem/16);
    }
}

.accordion__toggle-icon{
    color: var(--color-primary);
    font-size: calc(10rem/16);
    transform: rotate(180deg);

}

.collapsed .accordion__toggle-icon{
    transform: rotate(0deg);
}