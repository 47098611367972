.anchornav {
    font-size: calc(16rem/16);
    background: white;
    @media (max-width: 767px) {
        display: none;
    }
    @media (min-width: 768px) {
        letter-spacing: .2px;

    }
}

.anchornav__main-item {
    position: relative;
    background: var(--color-primary);
    padding: calc(14rem/16) calc(4rem/16) calc(14rem/16) 1rem;
}

.anchornav__main-item:before {
    content: '';
    position: absolute;
    right: 0;
    top: -50px;
    bottom: 0;
    width: 100rem;
    background: var(--color-primary);
    z-index: -1;
}

/*.anchornav__share-btn {*/
/*    background: var(--color-primary);*/
/*    @media (max-width: 768px) {*/
/*        color: black;*/
/*    }*/
/*}*/

.anchornav__share-btn:focus {
    color: black;
}

.anchornav__share-btn.active {
    background: var(--color-primary-light);
}

@media (min-width: 768px) {
    .anchornav__share-btn:before {
        content: '';
        position: absolute;
        left: 0;
        top: -50px;
        bottom: 0;
        right: 0;
        background: var(--color-primary);
        z-index: 0;
    }

}

.anchornav__share-container {
    display: flex;
    align-items: center;
    @media (max-width: 767px) {
        position: absolute;
        right: .5rem;
        top: calc(100% + 1rem);
        border-radius: 50%;
    }
    @media (min-width: 768px) {
        background: white;
        border-bottom-left-radius: calc(32rem/16);
    }
}

.anchornav__share-container--shadow {
    box-shadow: 0 6px 10px 0 rgba(34,43,64,0.1);
}

.anchornav__share-dropdown {
    display: flex;
    flex-display: column;
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 1;
}

.anchornav__main-item:after {
    content: '';
    position: absolute;
    left: 100%;
    top: 0;

    /* css arrow */
    width: 0;
    height: 0;
    border-style: solid;
    border-width: calc(26rem/16) 0 calc(26rem/16) 20px;
    border-color: transparent transparent transparent var(--color-primary);
    z-index: 1;
}

.anchornav__item {
    color: var(--color-text-muted);
    @media (min-width: 768px) {
        margin: 0 calc(20rem/16);
    }
}

.anchornav__item a {
    display: inline-block;
    position: relative;
    padding-top: calc(14rem/16);
    padding-bottom: calc(8rem/16);
    color: var(--color-dark-grey);
    font-family: var(--font-default-bold);
}

.anchornav__item.active a:after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 3px;
    background: var(--color-primary);
}

@media (min-width: 768px) {
    .anchornav-wrapper__container.anchornav-wrapper__container {
        padding-right: 0;
    }

    .anchornav__item a.active {
        color: var(--color-default);
    }

    .anchornav__items {
        overflow-x: scroll;
        white-space: nowrap;
        padding-left: calc(12rem/16);
    }
    
    .anchornav__count {
        display: none;
    }
}


@media (max-width: 767px) {
    .anchornav-wrapper__container.anchornav-wrapper__container {
        padding: 0;
    }
    .anchornav-wrapper {
        position: absolute;
        left: 0;
        right: 0;
        z-index: 90;
        top: calc((62rem + 8rem)/16);
        opacity: 0;
        visibility: hidden;
        transition: opacity .3s, visibility 0s linear .3s;
    }

    .show-anchornav .anchornav-wrapper {
        visibility: visible;
        opacity: 1;
        transition: opacity .3s, visibility 0s 0s;

    }

    .navbar-parent.is-open .anchornav-wrapper{
        visibility: hidden;
        opacity: 0;
    }

    .anchornav {
        display: flex;
        box-shadow: 0 10px 20px 0 rgba(34,43,64,0.1);
        border-radius: calc(160rem/16);
        font-size: calc(12rem/16);
    }

    .anchornav__main-item {
        display: none;
    }

    .anchornav__item {
        position: absolute;

        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        padding: 0 calc(8rem/16);
        margin: 0;

        display: flex;
        align-items: center;
        justify-content: center;

    }

    .anchornav__item a {
        opacity: 0;
        transition: opacity .2s;
        pointer-events: none;
    }

    .anchornav__item a.active {
        opacity: 1;
        pointer-events: auto;
    }


    .anchornav__share-btn {
        color: var(--color-text-default);
        background: white;
        border-color: white;
    }

    .anchornav__count {
        display: block;
        padding: 0 calc(20rem/16);
        border-right: 1px solid var(--color-light-grey);
    }
}

.anchornav__btn {
    padding: .875rem;
    border-radius: 0;
    color: var(--color-primary);
    display: flex;
    align-items: center;
}

.anchornav__btn:focus {
    color: var(--color-primary);
}

.anchornav__share-title {
    margin: 0 1rem;
}

.anchornav__scroll-indicator.anchornav__scroll-indicator {
    z-index: 1;
}


