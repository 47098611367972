.modal__close.modal__close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    padding: 1rem;
    z-index: 11;
    font-size: 1rem;
    color: var(--color-default);
}

.modal-body {
    padding: calc(32rem/16) calc(16rem/16);
    @media (min-width: 768px) {
        padding: calc(80rem/16); 
    }

}