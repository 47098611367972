.radio-button label {
    display: flex;
}

.radio-button__input {
    position: absolute;
    width: calc(1rem/16);
    height: calc(1rem/16);
    margin: calc(-1rem/16);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}

.radio-button__label {
    border-radius: 1000px;
    padding: calc(10rem/16) calc(32rem/16);
    background: var(--color-primary);

    cursor: pointer;
    font-size: calc(16rem/16);
    line-height: calc(20/16);
    opacity: .5;
}

.radio-button__input:checked ~ .radio-button__label {
    opacity: 1;
}

.radio-button__input:disabled ~ .radio-button__label.radio-button__label {
    background: var(--color-grey);
    color: var(--color-dark-grey);
    pointer-events: none;
}