.effect-timeline,
.effect-timeline-table-th{
    width: calc(336rem/16);
    @media screen and (min-width: 768px){
       width: calc(468rem/16);
    }
}

.effect-timeline{
    display: flex;
    position: relative;
}

.effect-timeline:before {
    content: '';
    position: absolute;
    top: calc(9rem/16);
    left: 0;
    right: calc(4rem/16);
    height: calc(1rem/16);
    background: var(--color-dark);
}

.effect-timeline .list-inline-item:not(:last-child){
    margin-right: calc(15rem/16);
    @media screen and (min-width: 768px){
        margin-right: calc(30rem/16);
    }
}

.effect-timeline__item {
    position: relative;
    z-index: 1;
}

.effect-timeline__circle-text{
    font-size: calc(15rem/16);
    margin-top: calc(2rem/16);
}

.effect-timeline__circle{
    width: calc(20rem/16);
    height: calc(20rem/16);
    border-radius: 50%;
    border: 1px solid var(--color-dark);
    position: relative;
    overflow: hidden;
    background-color: #fff;
}

.effect-timeline__circle-piece{
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: var(--color-dark);
}

.effect-timeline__circle-piece.zero{
    width: 0;
}

.effect-timeline__circle-piece.third{
    width: 33.33%;
}

.effect-timeline__circle-piece.half{
    width: 50%;
}

.effect-timeline__circle-piece.three-quarters{
    width: 75%;
}

.effect-timeline__circle-piece.full{
    width: 100%;
}

.effect-timeline__circle-piece.right{
    right: 0;
}

.effect-timeline__circle-piece.left{
    left: 0;
}