.title-block{
    margin-bottom: calc(35rem/16);
    
    @media screen and (min-width: 768px){
       margin-bottom: calc(55rem/16);
    }
}

.title-block--main {
    padding-top: calc(16rem/16);
    @media screen and (min-width: 768px){
        padding-top: calc(40rem/16);
    }
}