.main-search {
    position: relative;
}

.main-search__form-group {
    margin: 0;
}

.main-search__input.main-search__input.main-search__input {
    padding-right: calc(56rem/16);
    box-shadow: none;
@media (max-width: 767px) {
    height: calc(35rem/16);
    padding: .5rem 1rem;
}
}

@media (max-width: 767px) {
    .main-search__form-group .floating-label {
        top: 50%;
        left: 1rem;
    }

    .form-group.main-search__form-group.has-value .floating-label {
        transform: translateY(calc(-18rem / 16)) scale(.5);
    }
}

.main-search__button {
    width: calc(35rem/16);
    height: calc(35rem/16);
    padding: .5rem;
    border-radius: 100%;

    display: inline-flex;
    justify-content: center;
    align-items: center;

    margin-left: -52px;
    z-index: 1;
    position: absolute;
    right: 0;
    top: 0;

@media (min-width: 768px) {
    width: calc(52rem/16);
    height: calc(52rem/16);
}
}