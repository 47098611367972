.list-count{
    counter-reset: list-counter;
}

.list-count__item{
    display: flex;
}

.list-count__item+.list-count__item{
    margin-top: calc(18rem/16);
}

.list-count__count:before {
    counter-increment: list-counter;
    content: counter(list-counter);
}

.list-count__count{
    width: calc(18rem/16);
    height: calc(18rem/16);
    border-radius: 50%;
    background-color: var(--color-primary);
    font-family: var(--font-default-bold);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(10rem/16);
    flex: 0 0 auto;
    margin-right: calc(8rem/16);
    margin-top: calc(3rem/16);

    @media screen and (min-width: 768px){
        width: calc(25rem/16);
        height: calc(25rem/16);
        margin-top: calc(1rem/16);
        font-size: calc(12rem/16);
    }


}
