.expert-item{
    color: var(--color-text-muted);
    font-size: calc(16rem/16);
    background-color: #fff;
    padding: calc(15rem/16);
    
    @media screen and (min-width: 768px){
       height: 100%;
    }
}

.expert-item__body{
    margin-top: calc(-8rem/16);
}
.expert-item__pretitle{
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: calc(10rem/16);
    font-family: var(--font-default-bold);
    line-height: 1.1;
}

.expert-item__title {
    font-family: var(--font-default-bold);
    color: var(--color-default);
    text-transform: uppercase;
    line-height: 1.2;
}

.expert-item__text{
    line-height: calc(20/16);
    margin-top: calc(7rem/16);
}