.recipe-item{
    background-color: #fff;
    padding: calc(20rem/16) calc(15rem/16);
    
}
.recipe-item__hl{
    font-size: calc(16rem/16);
    font-family: var(--font-default-bold);
    margin-bottom: calc(15rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(18rem/16);
    }
}

.recipe-item__person{
    color: var(--color-text-muted);
    font-size: calc(16rem/16);
    line-height: 1.1;
    margin-bottom: calc(20rem/16);
    display: inline-block;
}

.recipe-item__list li:not(last-child) {
    margin-bottom: 13px;
}

.recipe-item hr{
    margin: calc(25rem/16) calc(-15rem/16);
}

.recipe-item__show-more-btn{
    padding-bottom: calc(2rem/16);
    position: relative;
    margin-top: calc(5rem/16);
}

.recipe-item__show-more-btn:after{
    content: '';
    position: absolute;
    bottom: 0;
    left: calc(-2rem/16);
    right: calc(-2rem/16);
    height: calc(2rem/16);
    background-color: var(--color-primary);
}