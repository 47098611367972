.slide {
    padding-top: calc(45rem/16);
    padding-bottom: calc(45rem/16);

    @media screen and (min-width: 768px){
        padding-top: calc(64rem/16);
        padding-bottom: calc(64rem/16);
    }
}

.slide.slide--padding-md {
    padding-top: calc(24rem/16);
    padding-bottom: calc(24rem/16);

    @media screen and (min-width: 768px){
        padding-top: calc(40rem/16);
        padding-bottom: calc(40rem/16);
    }
}

.slide.slide--pb-none {
    padding-bottom: 0;
}

.slide.slide--pt-none {
    padding-top: 0;
}

.breadcrumb-wrapper + .slide,
.breadcrumb-wrapper + .pimcore_area_content .slide {
    padding-top: calc(16rem/16);
    @media screen and (min-width: 768px){
        padding-top: calc(40rem/16);
    }
}