.quality-module-area__items{
    margin-top: calc(40rem/16);

    @media screen and (min-width: 768px){
        margin-top: calc(60rem/16);
    }
    
    @media screen and (max-width: 767px){
        padding: 0 calc(15rem/16);
    }
}