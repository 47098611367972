@font-face {
    font-family: "iconfont";
    src: url('/cache-buster-1629793179688/bundles/myfitnessguide/static/build/icons/font/iconfont.eot?#iefix') format('eot'),
    url('/cache-buster-1629793179688/bundles/myfitnessguide/static/build/icons/font/iconfont.woff') format('woff'),
    url('/cache-buster-1629793179688/bundles/myfitnessguide/static/build/icons/font/iconfont.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.icon {
    display: inline-block;
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}

.icon-lg {
    font-size: 1.3333333333333333em;
    line-height: 0.75em;
    vertical-align: -15%;
}
.icon-2x { font-size: 2em; }
.icon-3x { font-size: 3em; }
.icon-4x { font-size: 4em; }
.icon-5x { font-size: 5em; }
.icon-fw {
    width: 1.2857142857142858em;
    text-align: center;
}

.icon-ul {
    padding-left: 0;
    margin-left: 2.14285714em;
    list-style-type: none;
}
.icon-ul > li {
    position: relative;
}
.icon-li {
    position: absolute;
    left: -2.14285714em;
    width: 2.14285714em;
    top: 0.14285714em;
    text-align: center;
}
.icon-li.icon-lg {
    left: -1.85714286em;
}

.icon-rotate-90 {
    transform: rotate(90deg);
}
.icon-rotate-180 {
    transform: rotate(180deg);
}
.icon-rotate-270 {
    transform: rotate(270deg);
}
.icon-flip-horizontal {
    transform: scale(-1, 1);
}
.icon-flip-vertical {
    transform: scale(1, -1);
}
:root .icon-rotate-90,
:root .icon-rotate-180,
:root .icon-rotate-270,
:root .icon-flip-horizontal,
:root .icon-flip-vertical {
  filter: none;
}

.icon-arrow-left:before { content: "\EA01" }
.icon-arrow-long:before { content: "\EA02" }
.icon-arrow-right:before { content: "\EA03" }
.icon-bars:before { content: "\EA04" }
.icon-blog:before { content: "\EA05" }
.icon-capsule:before { content: "\EA06" }
.icon-check-circle:before { content: "\EA07" }
.icon-check:before { content: "\EA08" }
.icon-circle:before { content: "\EA09" }
.icon-close:before { content: "\EA0A" }
.icon-compare:before { content: "\EA0B" }
.icon-decrease-appetite:before { content: "\EA0C" }
.icon-drink:before { content: "\EA0D" }
.icon-dropdown:before { content: "\EA0E" }
.icon-egg:before { content: "\EA0F" }
.icon-elements:before { content: "\EA10" }
.icon-fb:before { content: "\EA11" }
.icon-few-additives:before { content: "\EA12" }
.icon-filter:before { content: "\EA13" }
.icon-fish:before { content: "\EA14" }
.icon-food:before { content: "\EA15" }
.icon-high-quality:before { content: "\EA16" }
.icon-info:before { content: "\EA17" }
.icon-ingredient:before { content: "\EA18" }
.icon-legumes:before { content: "\EA19" }
.icon-low-calories:before { content: "\EA1A" }
.icon-mail:before { content: "\EA1B" }
.icon-meat:before { content: "\EA1C" }
.icon-metabolism:before { content: "\EA1D" }
.icon-milk:before { content: "\EA1E" }
.icon-more:before { content: "\EA1F" }
.icon-muscle-building:before { content: "\EA20" }
.icon-non-gmo:before { content: "\EA21" }
.icon-nuts:before { content: "\EA22" }
.icon-pinterest:before { content: "\EA23" }
.icon-play:before { content: "\EA24" }
.icon-plus:before { content: "\EA25" }
.icon-products:before { content: "\EA26" }
.icon-quality-protein:before { content: "\EA27" }
.icon-recovery-phase:before { content: "\EA28" }
.icon-salad:before { content: "\EA29" }
.icon-search:before { content: "\EA2A" }
.icon-shake:before { content: "\EA2B" }
.icon-share:before { content: "\EA2C" }
.icon-soy-products:before { content: "\EA2D" }
.icon-star-filled:before { content: "\EA2E" }
.icon-star-outline:before { content: "\EA2F" }
.icon-train:before { content: "\EA30" }
.icon-twitter:before { content: "\EA31" }
.icon-warenkorb:before { content: "\EA32" }
.icon-weight-loss:before { content: "\EA33" }
.icon-whatsapp:before { content: "\EA34" }
.icon-whole-grain:before { content: "\EA35" }


:root {
--icon-arrow-left: "\EA01";
    --icon-arrow-long: "\EA02";
    --icon-arrow-right: "\EA03";
    --icon-bars: "\EA04";
    --icon-blog: "\EA05";
    --icon-capsule: "\EA06";
    --icon-check-circle: "\EA07";
    --icon-check: "\EA08";
    --icon-circle: "\EA09";
    --icon-close: "\EA0A";
    --icon-compare: "\EA0B";
    --icon-decrease-appetite: "\EA0C";
    --icon-drink: "\EA0D";
    --icon-dropdown: "\EA0E";
    --icon-egg: "\EA0F";
    --icon-elements: "\EA10";
    --icon-fb: "\EA11";
    --icon-few-additives: "\EA12";
    --icon-filter: "\EA13";
    --icon-fish: "\EA14";
    --icon-food: "\EA15";
    --icon-high-quality: "\EA16";
    --icon-info: "\EA17";
    --icon-ingredient: "\EA18";
    --icon-legumes: "\EA19";
    --icon-low-calories: "\EA1A";
    --icon-mail: "\EA1B";
    --icon-meat: "\EA1C";
    --icon-metabolism: "\EA1D";
    --icon-milk: "\EA1E";
    --icon-more: "\EA1F";
    --icon-muscle-building: "\EA20";
    --icon-non-gmo: "\EA21";
    --icon-nuts: "\EA22";
    --icon-pinterest: "\EA23";
    --icon-play: "\EA24";
    --icon-plus: "\EA25";
    --icon-products: "\EA26";
    --icon-quality-protein: "\EA27";
    --icon-recovery-phase: "\EA28";
    --icon-salad: "\EA29";
    --icon-search: "\EA2A";
    --icon-shake: "\EA2B";
    --icon-share: "\EA2C";
    --icon-soy-products: "\EA2D";
    --icon-star-filled: "\EA2E";
    --icon-star-outline: "\EA2F";
    --icon-train: "\EA30";
    --icon-twitter: "\EA31";
    --icon-warenkorb: "\EA32";
    --icon-weight-loss: "\EA33";
    --icon-whatsapp: "\EA34";
    --icon-whole-grain: "\EA35";
    
}