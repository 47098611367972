.highlight-teaser__content {
    position: relative;
    padding: calc(24rem/16) calc(16rem/16) calc(32rem/16);

    @media (min-width: 768px) {
        padding: calc(84rem/16) 2rem calc(84rem/16) 0;
        margin-top: calc(32rem/16);
    }
}

.highlight-teaser__content:before {
    content: "";
    position: absolute;
    background: var(--color-primary);
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    @media (min-width: 768px) {
        left: -9999rem;
    }
}

.highlight-teaser__title {
    font-size: responsive 35px 50px; /* min-size, max-size */
    font-range: 992px 1440px; /* viewport widths between which font-size is fluid */
    @media (max-width: 767px) {
        font-size: calc(24rem/16);
    }
}

.highlight-teaser__text {
    color: var(--color-dark);
    opacity: .4;
    font-family: var(--font-default-bold);
    letter-spacing: .8px;
}