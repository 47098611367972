.teaserbook-module{
    background-color: var(--color-primary);
    overflow: hidden;
}

.teaserbook-module__body{
    padding: calc(25rem/16);
    position: relative;
    z-index: 1;

    @media screen and (min-width: 1400px){
        padding: calc(90rem/16) 0 calc(90rem/16) calc(120rem/16);
    }
    @media screen and (min-width: 768px){
        padding: calc(40rem/16) 0 calc(40rem/16) calc(60rem/16);
    }
}

.teaserbook-module .check-list{
    margin-bottom: calc(30rem/16);
}

.teaserbook-module .check-list__text{
    font-family: var(--font-default-bold);
    font-size: calc(16rem/16);
    letter-spacing: calc(1rem/16);

    @media screen and (min-width: 768px){
        letter-spacing: calc(1.5rem/16);
        font-size: calc(17rem/16);
    }

    @media screen and (min-width: 1400px){
        letter-spacing: calc(3rem/16);
        font-size: calc(18rem/16);
    }
}

.teaserbook-module .check-list__icon{
    margin-top: calc(5rem/16);
}

.teaserbook-module__img{
    overflow: visible;
}