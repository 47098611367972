.navbar-usps {
    background: var(--color-primary);
    padding: calc(4rem/16) calc(8rem/16);
    text-align: center;
    font-size: calc(7rem/16);
    transition: transform .3s;
    @media (min-width: 768px) {
        font-size: calc(16rem/16);
        padding: calc(14rem/16);
    }
    @media (max-width: 767px) {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 11;
    }
}

.navbar-usps__icon {
    width: calc(8rem/16);
    height: calc(8rem/16);
    margin-right: calc(4rem/16);

    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: white;

    border-radius: 50%;
    font-size: 3px;
    vertical-align: .16em;
    @media (min-width: 768px) {
        width: calc(16rem/16);
        height: calc(16rem/16);
        margin-right: calc(4rem/16);
        font-size: calc(6rem/16);
    }
}

@media (min-width: 768px) {
    .navbar-parent.is-affix .navbar-usps {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;

    }

    .navbar-parent.scrolled-down .navbar-usps {
        transform: translateY(-100%);
    }
}

@media (max-width: 767px) {
    .navbar-parent.scrolled-down .navbar-usps {
        transform: translateY(100%);
    }
}
