.form-errors.invalid-feedback {
    display: block;
}
.form-errors:empty,
.is-valid .form-errors {
    display: none;
}
.invalid-feedback {
    margin-left: calc(20rem/16);
}
.custom-checkbox .invalid-feedback {
    margin-left: 0;
    margin-top: 0;
    font-size: 100%;
}