.icon-text-badge{
    background-color: var(--color-dark-grey);
    color: #fff;
    font-size: calc(12rem/16);
    line-height: 1.2;
    letter-spacing: 0.2px;
    display: inline-block;
    border-radius: calc(1000rem/16);
    padding-right: calc(8rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(13rem/16);
    }

}

.icon-text-badge__icon{
    width: calc(20rem/16);
    height: calc(20rem/16);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #fff;
    border-radius: 50%;
    font-size: calc(10rem/16);
    margin-left: calc(-1rem/16);

    @media screen and (min-width: 768px){
        width: calc(23rem/16);
        height: calc(23rem/16);
    }
}

.icon-text-badge__text{
    position: relative;
    top: calc(-1rem/16);
}
