.navbar-parent {
    position: relative;
    z-index: 100;
}

.navbar-parent__inner {
    position: relative;
}

.navbar-parent__inner:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: calc(62rem/16);

    background-color: transparent;

    box-shadow: 0 6px 10px 0 rgba(34,43,64,.1);
    border-bottom-left-radius: calc(37rem/16);
    border-bottom-right-radius: calc(37rem/16);

    z-index: 10;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    @media (min-width: 768px) {
        height: calc(92rem/16);
    }
}

.navbar-parent.is-open .navbar-parent__inner:before{
    opacity: 1;
    visibility: visible;
}


.navbar{
    position: relative;
    padding: 0;
    border-bottom-left-radius: calc(37rem/16);
    border-bottom-right-radius: calc(37rem/16);
    background: white;
    box-shadow: 0 6px 10px 0 rgba(34,43,64,0.1);
    z-index: 3;
    @media screen and (max-width: 767px){
       height: calc(62rem/16);
       transition: transform .3s;
    }
}
.navbar-parent__inner.is-affix {
    position: fixed;
    top: calc(52rem/16);
    left: 0;
    right: 0;
    transition: transform .3s;
}
.navbar-parent__inner.scrolled-down {
    transform: translateY(calc(-52rem/16));
}
@media screen and (max-width: 767px){

    .navbar-parent__inner.is-affix {
        top: 0;
    }

    .navbar-parent__inner.scrolled-down {
        transform: translateY(-100%);
    }
}

.navbar-parent__inner.scrolled-up {
    transform: translateY(0);
}

.navbar-parent.is-open .navbar{
    @media screen and (max-width: 767px){
       background-color: #fff;
    }
}

.navbar>.container{
    @media screen and (max-width: 767px){
       flex-direction: row-reverse;
       padding: 0 calc(5rem/16);
    }
}

.navbar__main-row {
    flex: 1;
    align-items: center;
}

.navbar-col{
    @media screen and (min-width: 768px){
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }
}

.navbar-col.navbar-brand{
    @media screen and (min-width: 768px) and (max-width: 1350px){
       flex-grow: 0;
    }
}


.navbar-brand{
    text-align: center;
    margin: 0 auto;
}

.navbar-brand__img{
    height: calc(52rem/16);

    @media (max-width: 767px) {
        width: calc(52rem/16);
    }
    @media screen and (min-width: 768px){
        height: calc(48rem/16);
        margin: 0 auto;
    }

    @media screen and (min-width: 1100px){
        height: calc(62rem/16);
    }
}

.navbar-brand__img--small {
    @media (min-width: 1050px) {
        display: none;
    }    
}

.navbar-brand__img--big {
    @media (max-width: 1049px) {
        display: none;
    }
}

.navbar-main.navbar-collapse{
    @media screen and (max-width: 767px){
       flex-basis: 0;
    }
}

.navbar-main .nav-item{
    padding: 0 calc(8rem/16);

    @media (min-width: 768px) {
        padding: 0 calc(12rem/16);
    }
    @media screen and (min-width: 1200px){
        padding: 0 calc(18rem/16);
    }
}

.navbar-main .nav-item__main{
    @media screen and (min-width: 768px) and (max-width: 1199px){
       padding: 0 calc(7rem/16);
    }
}

.navbar-main .nav-link__main {
    position: relative;
    @media screen and (min-width: 768px){
        height: calc(92rem/16);
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    
    @media screen and (max-width: 767px){
       line-height: 1;
    }
}

.nav-item.active .nav-link__main:before,
.nav-item.active .navbar-console-link:before {
    content: "";
    position: absolute;
    background: var(--color-text-default);
    bottom: -1px;
    height: calc(3rem/16);
    left: calc(8rem/16);
    right: calc(8rem/16);
    @media (max-width: 767px) {
        bottom: 0;
    }
}

.nav-item.active .navbar-console-link:before {
    @media (min-width: 768px) {
        left: 0;
        right: 0;
    }
    @media (max-width: 767px) {
        bottom: calc(8rem/16);
    }
}

.navbar-main .nav-link__text{
    font-size: calc(10rem/16);
    font-family: var(--font-default-bold);
    letter-spacing: 0.2px;
    @media screen and (min-width: 768px){
        font-size: calc(16rem/16);
        line-height: 1.1;
    }
    @media screen and (min-width: 1200px){
        font-size: calc(18rem/16);
    }
}

.nav-link__subtext-wrapper{
    line-height: 1;
}

.nav-link__subtext{
    font-size: calc(8rem/16);
    letter-spacing: 0.3px;
    color: var(--color-dark);
    position: relative;

    @media screen and (min-width: 768px){
        font-size: calc(12rem/16);
    }
}

.nav-link__icon{
    position: absolute;
    font-size: calc(10rem/16);
    bottom: calc(1rem/16);
    right: calc(-14rem/16);
    color: var(--color-primary);

    @media screen and (max-width: 767px){
        display: none;
    }
}

.navbar-main>.navbar-nav{
    flex-direction: row;
    @media (max-width: 767px) {
        justify-content: space-around;
    }
}

/* Navbar Console */
.navbar-console-wrapper {
    @media (min-width: 768px) {
        display: flex;
        align-self: stretch;
        flex: 1;
        justify-content: flex-end;
    }
}


.navbar-console{
    @media screen and (min-width: 768px){
        justify-content: flex-end;
    }

    @media screen and (max-width: 767px){
       position: fixed;
        bottom: calc(19rem/16);
        left: 0;
        right: 0;
        flex-direction: row;
        z-index: 5;
        display: flex;
        transition: transform .3s;

        border-top-left-radius: calc(26rem/16);
        border-top-right-radius: calc(26rem/16);
        box-shadow: 0 -6px 10px 0 rgba(34,43,64,0.1);
        padding: 0 calc(27rem/16) 0 calc(16rem/16);
        background-color: #fff;
    }
}

@media (max-width: 767px) {
    .navbar-parent.scrolled-down .navbar-console {
        transform: translateY(calc(19rem/16));
    }
    
}

.navbar-console-item {
    text-align: center;
    
    @media screen and (min-width: 768px){
        display: flex;
        align-items: center;
    }
    
    @media screen and (max-width: 767px){
        flex: 0 0 auto;
        max-width: 100%;
    }
}

.navbar-console-item--search {
    max-width: calc(192rem/16);
    @media (min-width: 1200px) {
        max-width: calc(224rem/16);
    }
    @media screen and (max-width: 767px) {
        flex: 1;
        padding: calc(10rem/16) 0;
    }
}

.navbar-console-item+.navbar-console-item{
    margin-left: calc(6rem/16);

    @media screen and (min-width: 768px){
        margin-left: calc(20rem/16);
    }
    @media screen and (min-width: 1400px){
        margin-left: calc(40rem/16);
    }
}

.navbar-console-link {
    display: flex;
    align-items: center;
    align-self: stretch;
    position: relative;
    @media (max-width: 767px) {
        flex-direction: column;
        padding: calc(14rem/16) calc(8rem/16);
    }
}

.navbar-console-link__icon {
    @media (min-width: 768px) {
        margin-right: .5rem;
    }
}

.navbar-console-link__text{
    font-size: calc(10rem/16);
    letter-spacing: 0.2px;

    @media screen and (min-width: 768px){
        font-size: calc(14rem/16);
    }
    @media screen and (min-width: 1400px){
        font-size: calc(18rem/16);
    }
}

/* hover effect */

.navbar-parent.is-open .nav-item {
    opacity: .2;
    transition: opacity .3s;
}

.navbar-parent.is-open .nav-item__main.is-open,
.navbar-parent.is-open .nav-item__main.is-open .nav-item {
    opacity: 1;
}

