.product-content-teaser{
    background-color: #fff;
    @media screen and (min-width: 768px){
        height: 100%;
        display: flex;
        flex-direction: column;
    }
}

.product-content-teaser__content{
    position: relative;
    @media screen and (min-width: 768px){
       height: 100%;
    }
}

.product-content-teaser__content:after {
    content: '';
    width: calc(68rem/16);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: var(--color-primary);

    @media screen and (min-width: 768px){
        width: calc(112rem/16);

    }
}

/*.product-content-teaser>.row{
    @media screen and (min-width: 768px){
       height: 100%;
    }
}*/

.product-content-teaser__body{
    padding: calc(20rem/16) 0 calc(20rem/16) calc(20rem/16);
    position: relative;
    z-index: 1;
    @media screen and (min-width: 768px){
        padding: calc(22rem/16) 0 calc(22rem/16) calc(30rem/16);
        height: 100%;
        display: flex;
        flex-direction: column;
    }
}

.product-content-teaser__title{
    font-size: calc(16rem/16);
    letter-spacing: 0.2px;
    font-family: var(--font-default-bold);

    @media screen and (min-width: 768px){
        font-size: calc(22rem/16);
    }
}

.product-content-teaser__text{
    font-size: calc(14rem/16);
    line-height: calc(25/16);
    margin-top: calc(10rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(16rem/16);
    }
}

.product-content-teaser__price{
    font-family: var(--font-default-bold);
    font-size: calc(20rem/16);
}

.product-content-teaser__link{
    font-size: calc(16rem/16);
    padding-bottom: calc(1rem/16);
    position: relative;
    display: inline-block;
    margin-top: calc(5rem/16);
}

.product-content-teaser__link:after{
    content: '';
    position: absolute;
    left: calc(-1rem/16);
    right: calc(-1rem/16);
    bottom: 0;
    height: calc(2rem/16);
    background-color: var(--color-primary-light);
}

.product-content-teaser__body-bottom{
    padding-top: calc(30rem/16);

    @media screen and (min-width: 768px){
       padding-top: calc(50rem/16);
        margin-top: auto;
    }
}

.product-content-teaser__btn{
    border-radius: 0;
    
    @media screen and (min-width: 768px){
       margin-top: auto;
    }
}

.product-content-teaser__img{
    max-width: calc(110rem/16);
    float: right;
    margin: calc(30rem/16) calc(10rem/16) calc(10rem/16);
    position: relative;
    z-index: 1;

    @media screen and (min-width: 768px){
        margin: calc(55rem/16) calc(32rem/16) calc(10rem/16) calc(20rem/16);
        max-width: calc(155rem/16);
    }

}

.product-content-teaser__testwinner{
    position: absolute;
    top: calc(15rem/16);
    right: calc(15rem/16);
    width: calc(52rem/16);
    z-index: 1;

    @media screen and (min-width: 768px){
        top: calc(20rem/16);
        right: calc(20rem/16);
        width: calc(80rem/16);
    }
    
    @media screen and (max-width: 767px){
    }
}