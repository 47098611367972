:root {
    --row-gutter: calc(16rem/16);
}

html {
    -ms-overflow-style: scrollbar; /*prevent scrollbar from overlapping content in IE*/
}
body {
    position: relative;
    overflow-x: hidden;
}
@media screen and (max-width: 767px) {
    body {
        min-width: calc(320rem/16);
        max-width: 100%;
        overflow-x: hidden;
    }
}
.container.container {
    max-width: calc(1552rem/16); /* 1520px + 16px padding */
    width: 100%;
    padding-left: calc(16rem/16);
    padding-right: calc(16rem/16);
}

.container-narrow{
    max-width: calc(1040rem/16); /* 1008px container breite + 32px padding */
    width: 100%;
    margin: 0 auto;
    padding-left: calc(16rem/16);
    padding-right: calc(16rem/16);
}

.container-big{
    max-width: calc(1846rem/16); /* 1814px container breite + 16px padding */
    width: 100%;
    margin: 0 auto;
    padding-left: calc(16rem/16);
    padding-right: calc(16rem/16);
}

.container-form-single-col {
    max-width: calc(370rem/16); /* 1008px container breite + 32px padding */
    width: 100%;
    margin: 0 auto;
    padding-left: calc(16rem/16);
    padding-right: calc(16rem/16);
}

@media screen and (min-width: 1552px) {
    .container__with-to-right {
        width: calc(100vw - calc((100vw - 1502px)/2));
    }

}

@media (max-width: 767px) {
    .container--pr-0-xs.container--pr-0-xs {
        padding-right: 0;
    }

    .container--table-xs.container--table-xs {
        padding-right: 0;
        padding-left: 8px;
    }

}