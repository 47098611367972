@import "@elements/cookie-permissions/style/cookie-selection.css";
@import "@elements/cookie-permissions/style/cookie-toggle.css";
/*@import "@elements/cookie-permissions/style/cookie-group.css";*/

:root {
    --cookie-toggle-switch-width: calc(60rem/16);
    --cookie-toggle-switch-height: calc(var(--cookie-toggle-switch-width)/60*34);
    --cookie-toggle-switch-toggle-size: calc(var(--cookie-toggle-switch-height)/34*26);
}

.modal--cookie .modal-body {
    padding: 0;
}

.cookie-modal .modal-body {
    padding: 2rem 1rem;
}

.cookie-bar__top {
    background: var(--color-primary);
    padding: 1rem;
    @media (min-width: 768px) {
        padding: calc(48rem/16) 1rem 2rem;
    }
}

.cookie-bar__bottom {
    text-align: center;
    padding: calc(24rem/16) calc(16rem/16);

    margin: 0 auto;
    @media (min-width: 768px) {
        padding: calc(48rem/16);
    }
    @media (min-width: 1200px) {
        max-width: 80%;
    }
}

.cookie-bar__title {
    font-size: calc(15rem/16);
    text-transform: uppercase;
    font-family: var(--font-default-bold);
    font-weight: bold;
    align-items: center;
    justify-content: center;
    @media (min-width: 768px) {
        margin-bottom: -3rem;
        font-size: calc(20rem/16);
    }
    @media (min-width: 1200px) {
        margin-bottom: -4rem;
        font-size: calc(35rem/16);
    }
}

.cookie-bar__cookie {
    width: calc(48rem/16);
    background: white;
    border-radius: 100%;
    padding: 10%;
    box-shadow: 0 10px 20px 0 rgba(34, 43, 64, .1);
    @media (min-width: 768px) {
        width: calc(96rem/16);
    }
    @media (min-width: 1200px) {
        width: calc(140rem/16);
    }
}

.cookie-bar__buttons {
    margin-top: calc(16rem/16);
    @media (min-width: 768px) {
        margin-top: calc(24rem/16);
    }
}

.cookie-bar__btn {
    margin-left: .75rem;

    @media screen and (max-width: 767px) {
        margin-top: .5rem;
        margin-left: 0;
    }
}

.cookie-bar__detail {
    font-family: var(--font-default-bold);
}
