.breadcrumb-wrapper {
    position: relative;
}

.breadcrumb-wrapper:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 100%;
    height: calc(160rem/16);
    z-index: -1;
}

.breadcrumb-wrapper.bg-slide-dark:before {
    background: var(--color-light-dark);
}

.breadcrumb-wrapper.bg-slide-light:before {
    background: var(--color-light);
}

.breadcrumb {
    white-space: nowrap;
    overflow-x: auto;
    margin-bottom: 0;
    @media (max-width: 767px) {
        font-size: calc(12rem/16);
        padding: 1rem 0 0;
    }
}

