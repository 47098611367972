.check-list__item{
    display: flex;
}

.check-list__item + .check-list__item{
    margin-top: calc(15rem/16);

    @media screen and (min-width: 768px){
        margin-top: calc(20rem/16);
    }
}

.check-list__icon{
    width: calc(20rem/16);
    height: calc(20rem/16);
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    justify-content: center;
    background-color: var(--color-primary);
    color: var(--color-default);
    border-radius: 50%;
    font-size: calc(8rem/16);
    margin-top: calc(3rem/16);
    margin-right: calc(9rem/16);
}


.check-list--white .check-list__icon{
    background-color: #fff;
}