.show-all-content__content{
    position: relative;
    /*    max-height: 70px;*/
    overflow: hidden;
}


/*
.show .show-all-content__content{
    max-height: 100vh;
}
*/

.show-all-content__max-height .show-all-content-btn{
    display: block;
}

.show-all-content__max-height.show .show-all-content__content{
    max-height: 100%!important; /* overwrite inline */

}

.show-all-content-btn__less{
    display: none;
}

.show-all-content__max-height.show .show-all-content-btn__less{
    display: block;
}

.show-all-content__max-height.show .show-all-content-btn__more{
    display: none;
}


.show-all-content__btn{
    padding: 0;
    font-family: var(--font-default-bold);
    background-color: transparent;
    border: none;
    color: var(--color-default);
}

.show-all-content__btn:focus{
    outline: 0;
}

.show-all-content__btn .icon{
    font-size: calc(5rem/16);
    vertical-align: calc(1rem/16);
}